<template>
  <div>
    <HeaderView :page_index="0"></HeaderView>
    <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left; margin: 30px 30px 30px 30px;" />
    <div v-else class="home">
      <el-aside width="200px" style="width:200px;">
        <div></div>
      </el-aside>
      <el-container style="text-align: left; padding-left: 20px;">
        <el-main>
          <el-skeleton v-if="loading2" :rows="5" animated  style="text-align: left;" />
          <div v-else-if="list.length==0">没有收藏</div>
          <div v-else>
            <OnePersonCanPull :person_info="info" tag="middle"  v-for="(info, index) in list" :key="index"  />
            <div class="footer">
              <el-pagination v-if="num_page!==0" @current-change="get_list" :current-page="current_page"  layout="prev, pager, next, jumper" :page-count="num_page"></el-pagination>
            </div>
          </div>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import OnePersonCanPull from '@/components/items/OnePersonCanPull'
import HeaderView from '@/components/other/HeaderView'

export default {
  name: 'Marks',
  mounted () {
    this.get_list(1)
  },
  components: {
    OnePersonCanPull, HeaderView
  },
  data () {
    return {
      loading: true,
      loading2: false,
      loading3: false,
      is_long: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      list: [],
      num_page: 0,
      current_page: 1
    }
  },
  methods: {
    get_list (page) {
      axios.post('/mark_person_list/', { page: page })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.list = res.data.data.list
          this.num_page = res.data.data.num_page
          this.loading = false
          this.loading2 = false
        })
    }
  }
}
</script>
<style scoped>

</style>
